<template>
    <section class="content">
        <header class="content__title">
            <h1>Daily Guide Devotional </h1>
            <small>A list of news spanning various categories</small>
            <div class="actions">
                <button class="btn btn-primary" @click="add">
                    <i class="zmdi zmdi-plus"></i>
                    Add Lesson
                </button>

                <!-- <button class="btn btn-danger" :disabled="is_refactoring" @click="refactor">
                    <span v-if="is_refactoring">Processing</span>
                    <span v-else>Refactor</span>
                    {{ devotionalName }}
                </button> -->
            </div>
        </header>

        <!-- <ul>
            <li v-for="(item, i) in results" :key="i" @click="getYear(item.id, i)">
                <div>{{ item.id }}</div>
                <div>{{ item.months }}</div>
                <pre>{{ item.items }}</pre>
            </li>
        </ul> -->

        <!-- <pre>{{ monthNames }}</pre> -->

        <div class="card">
            <div class="card-header bg-blue">
                <select class="devotional-year" v-model="year" @change="setYear">
                    <option v-for="year in years" :key="year">{{ year }}</option>
                </select>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <ul class="devotional-sidebar">
                        <li @click="home">Months</li>
                        <li v-for="(month, i) in months" :key="i" @click="loadByMonth(month, i)" :class="isSelectMonth(month)">
                            {{ $moment(month + 1, "M").format("MMMM") }}
                        </li>
                    </ul>
                </div>
                <div class="col-md-10">
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs'
export default {
    data() {
        return {
            months: Array.from(Array(12).keys()),
            year: this.$store.state.devotionalYear,
            isSelectMontha: "selected-month",
            is_refactoring: false,

            results: [],
            devotionalName: 'dailypower',
        }
    },
    methods: {
        add() {
            this.$router.push("/dailyguide/add")
        },
        async loadByMonth(month) {
            const monthName = this.$moment(month + 1, "M").format("MMMM")
            this.$router.push("/dailyguide/m/" + monthName)
        },
        home() {
            this.$router.push("/dailyguide")
        },
        isSelectMonth(month) {
            return this.$route.params.month == this.$moment(month + 1, "M").format("MMMM") ? "selected-month" : null
        },
        setYear() {
            this.$store.dispatch("changedevotionalYear", this.year)
        },
        async refactor() {
            try {
                this.is_refactoring = true;
                const snapshot = await this.$db
                    .collection("devotionals")
                    .doc(this.devotionalName)
                    .collection("year")
                    .get();
                const docs = snapshot.docs.map((doc) => {
                    let d = doc.data();
                    d.id = doc.id;
                    d.months = [];
                    d.items = [];
                    return d;
                });
                // this.results = docs;

                for (const d of docs) {
                    this.getYear(d.id);
                }

                alert('done');

            } catch (error) {
                console.log(error);
            } finally {
                this.is_refactoring = false;
                
            }
        },
        async getYear(id) {

            let devotionalsRef = this.$db.collection(this.devotionalName);

            let _year = String(id);
            // let items = [];
            for (const month of this.monthNames) {
                let snapshot = await this.$db
                    .collection("devotionals")
                    .doc(this.devotionalName)
                    .collection("year")
                    .doc(_year)
                    .collection(month)
                    .get();
                let dovoItems = snapshot.docs.map((doc) => {
                    let d = doc.data();
                    d.id = doc.id;
                    return d;
                });

                // items = dovoItems;

                for (const devo of dovoItems) {
                    devo.year = _year;
                    await devotionalsRef.doc(devo.id).set(devo);
                }
                // this.results[index].items.push(items);
                // this.results[index].months.push(month);

            }
        }
    },
    computed: {
        years() {
            let currentYear = dayjs().year() + 5
            let startYear = 2019
            let years = []
            while (currentYear >= startYear) {
                let year = currentYear--
                years.push(year)
            }
            return years;
        },
        monthNames() {
            let names = [];
            for (let i = 0; i < 12; i++) {
                const monthName = dayjs().month(i).format('MMMM');
                names.push(monthName);
            }
            return names;
        },
        currentMonth() {
            return dayjs().format("MMMM");
        }
    },
}
</script>
